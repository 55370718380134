.legal-page {
  width: 60%;
  margin: 50px auto;
  text-align: center;
}
@media (max-width: 700px) {
  .legal-page {
    width: 85%;
    margin: 30px auto;
  }
}
.legal-page p,
.legal-page ol,
.legal-page ul {
  text-align: left;
  line-height: 1.38;
  letter-spacing: 0.5px;
}
.legal-page p li,
.legal-page ol li,
.legal-page ul li {
  margin: 10px auto;
}
.legal-page ul li {
  list-style-type: disc;
}
.legal-page svg {
  width: 100px;
  margin-bottom: 100px;
  height: auto;
}
@media (max-width: 700px) {
  .legal-page svg {
    margin-bottom: 40px;
  }
}
.legal-page svg:hover {
  cursor: pointer;
}
.legal-page .headline {
  color: #001fae;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.7px;
  text-decoration: underline;
}
.legal-page .back {
  background-color: #001fae;
  border-radius: 100%;
  height: 27px;
  width: 27px;
  position: absolute;
  left: 0;
  top: 0;
  margin: 30px;
}
@media (max-width: 700px) {
  .legal-page .back {
    margin: 20px;
  }
}
.legal-page .back__btn {
  color: #fff;
}
.legal-page .back__btn i {
  font-size: 27px;
  line-height: 1;
}
